import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bench Press & Strict Pullups 4×8\\@75% 1RM`}</p>
    <p>{`then,`}</p>
    <p>{`200M Run`}</p>
    <p>{`21-HSPU’s`}</p>
    <p>{`21-Ring Rows`}</p>
    <p>{`200M Run`}</p>
    <p>{`15-HSPU’s`}</p>
    <p>{`15-Ring Rows`}</p>
    <p>{`200M Run`}</p>
    <p>{`9-HSPU’s`}</p>
    <p>{`9-Ring Rows`}</p>
    <p>{`200M Run`}</p>
    <p>{`for time.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Work on our back lot is underway! You will not be able to use the
Washington Street entrance this week.  Get ready for some outdoor wods
soon!`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Reminder:  Bootcamp classess are Tuesday-Friday at 9:30 and are
free for non members the rest of this month so spread the word.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Evening CrossFit classes are now offered Monday-Friday at 4:00,
5:00 & 6:00pm.`}</em></strong>{` `}<strong parentName="p">{`*   *`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      